import { Component } from 'react'
import { motion } from 'framer-motion'

class Youtube extends Component {
  static defaultProps = {
    embed: 'SEM_URL',
    title: 'Youtube Video'
  }

  render() {
    const { props } = this
    const embedLink = `https://www.youtube.com/embed/${props.embed}`
    const divStyle = {
      position: 'relative',
      width: '100%',
      height: 0,
      paddingBottom: '56.25%'
    }
    const frameStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    }

    return (
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        style={divStyle}
      >
        <iframe
          title={props.title}
          style={frameStyle}
          src={embedLink}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </motion.div>
    )
  }
}

export default Youtube
