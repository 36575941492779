import { Component } from 'react'
import { motion } from 'framer-motion'

class Button extends Component {
  static defaultProps = {
    text: 'SAIBA MAIS',
    link: '/#',
    color: '#00cc55',
    hoverColor: '#00aa33',
    padding: '10px 20px',
    radius: '5px',
    textColor: 'white',
    textSize: '12px',
    textWeight: 'bold',
    glow: true
  }

  render() {
    const { props } = this
    const styles = {
      background: props.color,
      borderRadius: props.radius,
      padding: props.padding,
      color: props.textColor,
      fontWeight: props.textWeight,
      fontSize: props.textSize,
      cursor: 'pointer',
      filter: props.glow ? `drop-shadow(0px 5px 5px ${props.color}70)` : ''
    }

    return (
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
      >
        <a href={props.link} style={{textDecoration: 'none'}}>
          <motion.button
            whileHover={{
              scale: 1.1,
              backgroundColor: props.hoverColor,
              transition: { duration: 0.2 }
            }}
            whileTap={{ scale: 0.9 }}
            style={styles}
            className="btn"
          >
            {props.text}
          </motion.button>
        </a>
      </motion.div>
    )
  }
}

export default Button
