import { Component } from 'react'
import { motion } from 'framer-motion'

class Text extends Component {
  static defaultProps = {
    strong: [0, 0],
    baseColor:'white',
    strongColor: '#00cc55',
    textSize: '16px',
    baseWeight: 400,
    strongWeight: 700
  }

  render() {
    const { props } = this
    const baseStyle = {
      color: props.baseColor,
      fontWeight: props.baseWeight,
      fontSize: props.textSize,
      textAlign: 'left'
    }
    const spanStyle = {
      fontWeight: props.strongWeight,
      color: props.strongColor
    }
    const childSplit = props.children.split(' ')
    var txtOne = '',
      txtTwo = '',
      txtThree = ''

    for (var i = 0; i < childSplit.length; i++) {
      if (i < props.strong[0] - 1) {
        txtOne += `${childSplit[i]} `
      } else if (i < props.strong[0] + props.strong[1] - 1) {
        txtTwo += `${childSplit[i]} `
      } else {
        txtThree += `${childSplit[i]} `
      }
    }

    return (
      <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}>
        <p style={baseStyle}>
          {txtOne}
          <span style={spanStyle}> {txtTwo} </span>
          {txtThree}
        </p>
      </motion.div>
    )
  }
}

export default Text
