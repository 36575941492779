import Text from '../atoms/Text'
import List from '../atoms/List'
import Button from '../atoms/Button'
import Youtube from '../atoms/Youtube'
import DoubleGrid from '../molecules/DoubleGrid'

function ListVideoFlow() {
  const text = `Freela Produtivo é a melhor ferramenta para você, freelancer, organizar sua vida. Traga agilidade e produtividade às suas atividades com uma série de templates para gerenciar projetos, contatos com clientes e vida financeira!`

  return (
    <DoubleGrid grid="5fr 7fr">
      <div>

        <Text strong={[14, 3]} strongColor="#5b76df">
          {text}
        </Text>

        <List
          list={[
            'Gestão de Projetos',
            'Prospecção de Clientes',
            'Controle Financeiro'
          ]}
          iconColor="#6a89f6"
        />

        <Button
          color="#6a89f6"
          hoverColor="#5b76df"
          link="https://pay.kiwify.com.br/b9TECOj"
        />
      </div>

      <div>
        <Youtube embed="L6kgMZImVJ4" />
      </div>
    </DoubleGrid>
  )
}

export default ListVideoFlow
