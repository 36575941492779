import ListVideoFlow from '../organisms/ListVideoFlow'
import Title from '../atoms/Title'

function LandingPage() {
  const title = 'OTIMIZE SUA VIDA COM FREELA PRODUTIVO'

  return (
    <div>
    <section>
        <Title textAlign="center" strong={[5, 2]} strongColor="#6a89f6">
          {title}
        </Title>
      <ListVideoFlow />
    </section>
    </div>
  )
}

export default LandingPage
