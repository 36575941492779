import './App.css'
import LandingPage from './components/templates/LandingPage'

function App() {
  return (
    <div className="App">
      <LandingPage />
    </div>
  )
}

export default App
