import { Component } from 'react'
import { motion } from 'framer-motion'

class List extends Component {
  static defaultProps = {
    list: [
      'Escreva aqui o item 1',
      'Depois o item 2 aqui',
      'E quantos mais quiser'
    ],
    iconColor: '#00cc55',
    textColor: 'white',
    textSize: '16px',
    iconSize: '24px',
    iconClass: 'fa-solid fa-check',
    iconMargin: '8px',
    lineHeight: 1.5,
    textWeight: 400
  }

  render() {
    const { props } = this

    const ulStyle = {
      listStyle: 'none'
    }

    const liStyle = {
      display: 'flex',
      alignItems: 'center',
      fontSize: props.textSize,
      lineHeight: props.lineHeight,
      color: props.textColor
    }

    const iStyle = {
      fontSize: props.iconSize,
      marginRight: props.iconMargin,
      color: props.iconColor
    }
    return (
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
      >
        <ul style={ulStyle}>
          {props.list.map((item) => (
            <li style={liStyle}>
              <i className={props.iconClass} style={iStyle} />
              {item}
            </li>
          ))}
        </ul>
      </motion.div>
    )
  }
}

export default List
